import axiosIns, {auth} from '@/libs/axios'

class TimerService {
  getTimer(id) {
    return axiosIns.get('task/timer/' + id + '/', {headers: auth()})
  }

  startTimer(id, data) {
    return axiosIns.post('task/timer/start/' + id + '/', data, {headers: auth()})
  }

  stopTimer(id, data) {
    return axiosIns.post('task/timer/pause/' + id + '/', data, {headers: auth()})
  }
}

export default new TimerService()
