<template>
  <div :class="{'flex-column': isView}" class="task">
    <div :class="{'task-left-side': !isView, 'w-50': isWidth50}" class="ob-scroll">
      <section v-if="!board.loading" class="tw-px-6 tw-py-4 tw-border tw-rounded-xl">
        <div>
          <!-- Заголовок -->
          <h1 class="tw-text-2xl tw-font-semibold tw-flex tw-items-center tw-text-gray-600">
            <span class="mr-50 tw-cursor-pointer" @click="copyTaskNumber">#{{ currentTask.id }}</span>
            <span v-show="!editTitle" style="border: 1px solid #fff" @click="focusOnEditInput">{{
              currentTask.subject
            }}</span>
            <input
              v-show="editTitle"
              ref="input-title"
              v-model="currentTask.subject"
              class="input-title tw-outline-none tw-py-[1px] tw-ps-[1px] tw-text-2xl tw-font-semibold"
              type="text"
              @focusout="editTitle = false"
              @input="showBlockUpdateTask = true"
            />
            <b-button
              v-if="!editTitle"
              v-tippy="{content: 'AI will create the title'}"
              class="btn-icon rounded-nrm p-25 ml-50"
              size="sm"
              variant="outline-info"
              @click="aiTitleForTask"
            >
              <feather-icon icon="PenToolIcon" />
            </b-button>
            <b-button
              v-if="!editTitle"
              v-tippy="{content: 'AI will correct the text'}"
              class="btn-icon rounded-nrm p-25 ml-50"
              size="sm"
              variant="outline-success"
              @click="aiCorrectionTitle"
            >
              <feather-icon icon="ZapIcon" />
            </b-button>
          </h1>

          <div class="pl-75 border-left-primary border-left-3">
            <p class="m-0 font-small-3 tw-text-gray-400 font-weight-bolder">
              {{ $t('Project') }}: {{ currentTask.project === null ? '' : currentTask.project.title }}
            </p>
            <p class="m-0 font-small-3 tw-text-gray-400 font-weight-bolder">
              {{ $t('Author') }}: {{ currentTask.created_by.title }}
            </p>
            <p class="m-0 font-small-3 tw-text-gray-400 font-weight-bolder">
              {{ $t('Created') }}: {{ handlerDateTime(currentTask.created_at) }}
            </p>
          </div>

          <!-- Поля задачи -->
          <div class="tw-mt-4 tw-grid tw-grid-cols-3">
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Sprint') }}:</p>
              <b-link class="ml-50 font-weight-bold">
                {{ currentTask.sprint ? '#' + currentTask.sprint.name : '' }}
              </b-link>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Type') }}:</p>
              <b-nav>
                <b-dropdown
                  :disabled="!isEditTask"
                  :text="firstUpperCase(currentTask.task_type)"
                  :variant="`flat-${handlerType(currentTask.task_type)}`"
                >
                  <b-dropdown-item
                    v-for="(item, index_3) in taskType"
                    :key="index_3"
                    @click="handlerFields('task_type', item.label)"
                    >{{ item.value }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Status') }}:</p>
              <b-nav>
                <b-dropdown
                  :text="currentTask.status === null ? '' : $t(currentTask.status.status)"
                  variant="flat-primary"
                >
                  <b-dropdown-item
                    v-for="(status, index) in statuses"
                    :key="index"
                    @click="
                      handlerFields('status', {
                        id: status.id,
                        status: status.status,
                        ordering: status.ordering,
                      })
                    "
                    >{{ status.value }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Assignee') }}:</p>
              <b-nav>
                <b-dropdown :text="currentTask.assigned.title" variant="flat-primary">
                  <b-dropdown-item
                    v-for="user in currentTask.project !== null ? currentTask.project.members : board.users"
                    :key="user.id"
                    @click="handlerFields('assigned', user)"
                    >{{ user.title }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>
              <b-button
                v-if="currentTask.assigned.id !== currentTask.performer && isEditTask"
                v-b-tooltip.hover.v-warning
                :title="$t('Make performer')"
                class="btn-icon rounded-nrm p-25"
                size="sm"
                variant="outline-warning"
                @click="handlerFields('performer', currentTask.assigned.id)"
              >
                <feather-icon icon="StarIcon" />
              </b-button>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Performer') }}:</p>
              <b-nav>
                <b-dropdown :disabled="!isEditTask" :text="performer" variant="flat-primary">
                  <b-dropdown-item
                    v-for="user in currentTask.project !== null ? currentTask.project.members : board.users"
                    :key="user.id"
                    @click="handlerFields('performer', user.id)"
                    >{{ user.title }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Start date') }}:</p>
              <b-nav>
                <date-picker
                  v-model="currentTask.start_date"
                  :disabled="!isEditTask"
                  :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
                  :placeholder="selectDate"
                  prefix-class="xmx"
                  type="date"
                  value-type="YYYY-MM-DD"
                  @change="handlerFields('start_date', $event)"
                ></date-picker>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Due date') }}:</p>
              <b-nav>
                <date-picker
                  v-model="currentTask.due_date"
                  :disabled="!isEditTask"
                  :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
                  :placeholder="selectDate"
                  prefix-class="xmx"
                  type="date"
                  value-type="YYYY-MM-DD"
                  @change="handlerFields('due_date', $event)"
                ></date-picker>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Priority') }}:</p>
              <b-nav>
                <b-dropdown
                  :disabled="!isEditTask"
                  :text="firstUpperCase(currentTask.priority)"
                  :variant="`flat-${handlerPriority(currentTask.priority)}`"
                >
                  <b-dropdown-item
                    v-for="(item, index_2) in priority"
                    :key="index_2"
                    @click="handlerFields('priority', item)"
                    >{{ item }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Estimated time') }}:</p>
              <b-form-input
                v-model="currentTask.estimateText"
                class="xmx-time"
                placeholder="HH:MM"
                @focusout="changeEstimatedTime(currentTask.estimateText)"
              ></b-form-input>
            </div>
            <div class="tw-flex tw-items-center task-field">
              <p class="m-0 tw-text-gray-600 tw-whitespace-nowrap">{{ $t('Spent time') }}:</p>
              <b-link v-tippy="{content: '123<br />123', allowHTML: true}" class="ml-50 font-weight-bold">
                {{ laborCosts }}
              </b-link>
            </div>
          </div>

          <div class="tw-mt-10 tw-p-4 tw-bg-slate-50/60 tw-rounded-lg tw-border tw-border-slate-100">
            <h4>Subtasks</h4>
            <table class="tw-w-full tw-table-auto">
              <thead>
                <tr class="tw-border-b-2">
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">ID</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Subject</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Type</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Status</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Assignee</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(subtask, index) in currentTask.subtasks" :key="index">
                  <td class="tw-py-2 tw-px-4 tw-text-sm">
                    <b-link :to="`/task/${subtask.id}`" target="_blank">{{ subtask.id }}</b-link>
                  </td>
                  <td class="tw-py-2 tw-px-4 tw-text-sm">{{ subtask.subject }}</td>
                  <td class="tw-py-2 tw-px-4">
                    <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                      <template #button-content>
                        <span class="tw-text-gray-500 tw-text-sm">{{ firstUpperCase(subtask.task_type) }}</span>
                      </template>
                      <b-dropdown-item
                        v-for="(item, index_3) in taskType"
                        :key="index_3"
                        @click="handlerSubtaskFields('task_type', index, item.label)"
                        >{{ item.value }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                  <td class="tw-py-2 tw-px-4">
                    <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                      <template #button-content>
                        <span class="tw-text-gray-500 tw-text-sm">{{
                          subtask.status === null ? '' : $t(subtask.status.status)
                        }}</span>
                      </template>
                      <b-dropdown-item
                        v-for="(status, index) in statuses"
                        :key="index"
                        @click="handlerSubtaskFields('status', index, status)"
                        >{{ status.value }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                  <td class="tw-py-2 tw-px-4">
                    <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                      <template #button-content>
                        <span class="tw-text-gray-500 tw-text-sm">{{ subtask.assigned.title }}</span>
                      </template>
                      <b-dropdown-item
                        v-for="user in subtask.project !== null ? subtask.project.members : board.users"
                        :key="user.id"
                        @click="handlerSubtaskFields('assigned', index, user)"
                        >{{ user.title }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                  <td>
                    <button
                      v-tippy="{content: 'Remove'}"
                      class="tw-rounded-lg tw-p-1 tw-bg-gray-100 tw-text-gray-500/80"
                      @click="removeParent(subtask)"
                    >
                      <svg
                        class="tw-min-w-4 tw-h-4"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="3"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5 12h14" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="tw-relative">
              <input
                v-model="subtaskValue"
                class="tw-mt-4 tw-mb-2 tw-w-full tw-border focus:tw-border-sky-400 tw-border-gray-200 tw-rounded-lg tw-outline-none tw-shadow-none tw-ps-4 tw-py-2.5 tw-text-slate-500 tw-text-sm tw-transition"
                placeholder="Write the number or title of the task"
                type="text"
                @input="onCreateOrFindTask"
              />
              <os-loader v-if="isLoaderSubtasks" class="tw-absolute tw-right-5 tw-top-[24px]" />
            </div>

            <table v-if="searchSubtasks.length > 0" class="tw-w-full tw-table-auto">
              <thead>
                <tr class="tw-border-b-2">
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">ID</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Subject</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Subtasks</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Type</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Status</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600">Assignee</th>
                  <th class="tw-py-2 tw-px-4 tw-font-semibold tw-text-sm tw-text-gray-600"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(subtask, index) in searchSubtasks">
                  <tr v-if="!currentTask.subtasks.map(el => el.id).includes(subtask.id)" :key="index">
                    <td class="tw-py-2 tw-px-4 tw-text-sm">
                      <b-link :to="`/task/${subtask.id}`" target="_blank">{{ subtask.id }}</b-link>
                    </td>
                    <td class="tw-py-2 tw-px-4 tw-text-sm">{{ subtask.subject }}</td>
                    <td class="tw-py-2 tw-px-4 tw-text-sm">{{ subtask.subtasks.map(el => el.id).join(', ') }}</td>
                    <td class="tw-py-2 tw-px-4">
                      <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                        <template #button-content>
                          <span class="tw-text-gray-500 tw-text-sm">{{ firstUpperCase(subtask.task_type) }}</span>
                        </template>
                        <b-dropdown-item
                          v-for="(item, index_3) in taskType"
                          :key="index_3"
                          @click="handlerSubtaskFields('task_type', index, item.label)"
                          >{{ item.value }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                    <td class="tw-py-2 tw-px-4">
                      <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                        <template #button-content>
                          <span class="tw-text-gray-500 tw-text-sm">{{
                            subtask.status === null ? '' : $t(subtask.status.status)
                          }}</span>
                        </template>
                        <b-dropdown-item
                          v-for="(status, index) in statuses"
                          :key="index"
                          @click="handlerSubtaskFields('status', index, status)"
                          >{{ status.value }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                    <td class="tw-py-2 tw-px-4">
                      <b-dropdown no-caret toggle-class="text-decoration-none tw-p-0" variant="link">
                        <template #button-content>
                          <span class="tw-text-gray-500 tw-text-sm">{{ subtask.assigned.title }}</span>
                        </template>
                        <b-dropdown-item
                          v-for="user in subtask.project !== null ? subtask.project.members : board.users"
                          :key="user.id"
                          @click="handlerSubtaskFields('assigned', index, user)"
                          >{{ user.title }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                    <td>
                      <button
                        v-tippy="{content: 'Add'}"
                        :disabled="subtask.subtasks.map(el => el.id).includes(currentTask.id)"
                        class="tw-rounded-lg tw-p-1 tw-bg-gray-100 tw-text-gray-500/80 disabled:tw-cursor-not-allowed disabled:tw-opacity-60"
                        @click="addParent(subtask)"
                      >
                        <svg
                          class="tw-min-w-4 tw-h-4"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 4.5v15m7.5-7.5h-15" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <!-- Заголовок описания -->
          <div class="d-flex align-items-center tw-mt-10">
            <h4 class="mb-1 d-flex align-items-center">
              {{ $t('Description') }}
            </h4>
          </div>
        </div>

        <!-- Редактор задачи -->
        <TaskBlocksReader
          ref="task-blocks-reader"
          v-model="currentTask.description"
          :read-only="!isEditTask"
          :task-data="currentTask"
          @change-reader="showBlockUpdateTask = true"
        />

        <!-- Сохранение блоков -->
        <!--        <b-button v-if="showEditor" class="mt-1" variant="primary" @click="saveEditor">{{ $t('Save') }}</b-button>-->
      </section>

      <!-- Скелетон для задачи -->
      <TaskLeftSkeleton v-else />
    </div>

    <div v-if="!isViewProps" :class="{'cursor-e': isWidth50}" class="breakdown-task tw-hidden md:tw-flex">
      <span :class="{'cursor-e': isWidth50}" @click="isWidth50 = !isWidth50"></span>
    </div>

    <!-- Комментарии -->
    <div :class="{'task-right-side': !isView, 'mt-2': isView, 'w-50': isWidth50}" class="ob-scroll">
      <section class="tw-px-6 tw-pb-3 tw-pt-2 tw-border tw-rounded-xl">
        <TaskNotes :estimated-time="currentTask.estimate" />
      </section>
    </div>

    <div :class="{show: showBlockUpdateTask}" class="block-updated-task">
      <span class="text-secondary">Вы внесли правки в задачу, сохраните изменения!</span>
      <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="ml-1" variant="flat-secondary" @click="getTask">
        {{ $t('Reset') }}
      </b-button>
      <b-button
        :disabled="savingProcess"
        class="ml-50 d-flex align-items-center"
        style="height: 30px"
        variant="success"
        @click="saveTask"
      >
        <template v-if="savingProcess">
          <b-spinner style="height: 15px; width: 15px; font-size: 10px" />
        </template>
        <template v-else>
          {{ $t('Save') }}
        </template>
      </b-button>
    </div>

    <ImageViewer />
  </div>
</template>

<script>
import {BButton, BDropdown, BDropdownItem, BFormInput, BLink, BNav, BSpinner, VBTooltip} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import TasksService from '@/services/tasks.service';
import TaskNotes from '@/components/Task/TaskNotes';
import TaskBlocksReader from '@/components/Task/TaskBlocksReader';
import ProjectsService from '@/services/projects.service';
import DatePicker from 'vue2-datepicker';
import {eventBus} from '@/main';
import {handlerDateTime} from '@/helpers/handlers';
import ImageViewer from '@/components/ImageViewer.vue';
import GptService from '@/services/gpt.service';
import TaskLeftSkeleton from '@/views/Tasks/TaskLeftSkeleton.vue';
import OsLoader from '@/components/OsLoader.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'Task',
  components: {
    OsLoader,
    TaskLeftSkeleton,
    ImageViewer,
    TaskNotes,
    BNav,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BFormInput,
    BSpinner,
    TaskBlocksReader,
    DatePicker,
  },
  props: {
    isViewProps: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showBlockUpdateTask: false,
      isWidth50: false,
      task: {
        priority: {
          title: 'Нормальный',
          value: 'normal',
        },
        estimated_time: '00:03',
      },
      priority: ['Low', 'Normal', 'High', 'Critical', 'Immediately'],
      taskType: [
        {label: 'epic', value: 'Epic'},
        {label: 'task', value: 'Task'},
        {label: 'bug', value: 'Bug'},
      ],
      hours: Array.from({length: 24}).map((_, i) => i),
      savingProcess: false,
      editTitle: false,
      subtaskValue: '',
      searchSubtasks: [],
      timer: null,
      isLoaderSubtasks: false,
    };
  },
  computed: {
    isView() {
      return this.$store.state.task.taskView;
    },
    isEditTask() {
      return ['manager', 'all'].includes(this.board.user.role_name);
    },
    performer() {
      const text = this.currentTask
        ? this.currentTask.project.members.find(el => this.currentTask['performer'] === el.id)
        : '';
      return text ? text.title : '-';
    },
    currentTask: {
      get() {
        return this.$store.state.task.task;
      },
      set(newValue) {
        this.$store.commit('task/GET_TASK', newValue);
      },
    },
    board() {
      return this.$store.state.osBoard;
    },
    statuses() {
      return this.$store.state.board.statuses.map(el => {
        return {
          ...el,
          value: this.$t(el.status),
        };
      });
    },
    laborCosts() {
      const time = this.$store.state.task.timeSpent;
      return `${Math.floor(time / 60)
        .toString()
        .padStart(2, '0')}:${(time - Math.floor(time / 60) * 60).toString().padStart(2, '0')}`;
    },
    selectDate() {
      return ['developer'].includes(this.$store.state.osBoard.user.role_name) ? 'No date' : 'Select date';
    },
  },
  mounted() {
    this.getTask();
    ProjectsService.getProjects();

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        eventBus.$emit('update-timer');
      }
    });
  },
  methods: {
    handlerDateTime,
    focusOnEditInput() {
      this.editTitle = true;
      this.$nextTick(() => {
        const editInputRef = this.$refs['input-title'];
        editInputRef.focus();
      });
    },
    copyTaskNumber() {
      this.$copyText(`issue-#${this.currentTask.id}`).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Task number copied for git',
              icon: 'BellIcon',
            },
          });
        },
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Can not copy!',
              icon: 'BellIcon',
            },
          });
        }
      );
    },
    handlerFields(name, value) {
      this.currentTask[name] = value;
      this.showBlockUpdateTask = true;
    },
    async handlerSubtaskFields(name, index, value) {
      const subtask = await this.currentTask.subtasks[index];
      subtask[name] = value;
      this.showBlockUpdateTask = true;
    },
    aiCorrectionTitle() {
      GptService.correctTitle(this.currentTask.subject)
        .then(response => {
          this.currentTask.subject = response.data.choices[0].message.content;
        })
        .catch(error => {
          console.error('Произошла ошибка:', error);
        });
    },
    aiTitleForTask() {
      GptService.getTitleForTask(this.$refs['task-blocks-reader'].$refs.editor[0].editor.getText())
        .then(response => {
          this.currentTask.subject = response.data.choices[0].message.content;
          this.aiCorrectionTitle();
        })
        .catch(error => {
          console.error('Произошла ошибка:', error);
        });
    },
    getTask() {
      TasksService.getTask(this.$route.params.id);
      this.showBlockUpdateTask = false;
    },
    firstUpperCase(text) {
      return text ? `${text[0].toUpperCase()}${text.slice(1)}` : '';
    },
    handlerPriority(priority) {
      const newText = this.firstUpperCase(priority);
      if (['Low'].includes(newText)) return 'secondary';
      if (['Normal'].includes(newText)) return 'primary';
      if (['High'].includes(newText)) return 'warning';
      if (['Critical', 'Immediately'].includes(newText)) return 'danger';
    },
    handlerType(type) {
      const newText = this.firstUpperCase(type);
      if (['Task'].includes(newText)) return 'primary';
      if (['Epic'].includes(newText)) return 'warning';
      if (['Bug'].includes(newText)) return 'danger';
    },
    changeEstimatedTime(val) {
      const hours_minutes = val.split(':');
      const hours = Number(hours_minutes[0]);
      const minutes = Number(hours_minutes[1]);
      this.currentTask.estimate = hours * 60 + minutes;
      this.currentTask.estimateText = val;
      this.showBlockUpdateTask = true;
    },
    formatFullName(name) {
      return name.split(' ')[0][0] + name.split(' ')[1][0];
    },
    saveTask() {
      this.savingProcess = true;
      const blocks = this.currentTask.description;
      if (blocks.length > 0) {
        blocks.forEach((el, index) => {
          TasksService.updateDescription(el.id, blocks[index]);
        });
      }

      const {status, start_date, due_date, assigned, estimate, priority, subject, task_type, performer} =
        this.currentTask;

      TasksService.updateTask(this.$route.params.id, {
        status: status.id,
        start_date,
        due_date,
        subject,
        assigned: assigned.id,
        estimate,
        task_type,
        performer,
        priority: priority.toLowerCase(),
      }).then(() => {
        TasksService.getTask(this.$route.params.id).then(() => {
          this.showBlockUpdateTask = false;
          setTimeout(() => {
            this.savingProcess = false;
          }, 700);
        });
      });
    },
    onCreateOrFindTask() {
      this.isLoaderSubtasks = true;
      // const taskNumber = this.subtaskValue.match(/#[0-9]+/g);
      // if (taskNumber) {
      //   console.log(taskNumber[0].replace('#', ''));
      // } else {
      //   console.log(this.subtaskValue);
      // }
      clearTimeout(this.timer);
      this.timer = null;
      if (this.subtaskValue) {
        this.timer = setTimeout(() => {
          TasksService.getSubtasks(this.subtaskValue).then(r => {
            this.searchSubtasks = r.data;
            this.isLoaderSubtasks = false;
          });
        }, 1000);
      } else {
        this.searchSubtasks = [];
      }
    },
    async addParent(subtask) {
      await TasksService.updateTask(subtask.id, {parent: this.$route.params.id});
      await this.getTask();
    },
    async removeParent(subtask) {
      await TasksService.updateTask(subtask.id, {parent: null});
      await this.getTask();
    },
  },
};
</script>

<style lang="scss">
$namespace: 'xmx';

$default-color: #6f6c7c;
$primary-color: #0095e8;

@import '~vue2-datepicker/scss/index.scss';

.task {
  display: flex;
  height: calc(100vh - 120px);

  .breakdown-task {
    min-width: 7px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: all 0.2s ease;

    .cursor-e {
      cursor: e-resize;
    }

    &:hover {
      background-color: #f1f5f9;
    }

    span {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      cursor: w-resize;
      background-color: #e2e8f0;
    }
  }

  .input-title {
    border: 0;
    font-weight: 600;
    font-size: 1.4rem;
    color: #5e5873;
    width: 100%;
  }

  .task-left-side {
    overflow-y: auto;
    width: 70%;
    padding-right: 0.4rem;
    padding-left: 1rem;
    flex-grow: 1;
    transition: width 0.2s ease-in-out;

    &.w-50 {
      width: 50%;
    }
  }

  .task-right-side {
    overflow-y: auto;
    width: 30%;
    padding-right: 0.4rem;
    padding-left: 1rem;
    transition: width 0.2s ease-in-out;

    &.w-50 {
      width: 50%;
    }
  }

  .nav-link-custom {
    padding: 0.5rem 0.6rem !important;
  }

  .btn {
    padding: 0.5rem;
  }

  .btn.disabled,
  .btn:disabled {
    opacity: 1;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .media-body {
    p {
      margin-bottom: 0;
    }
  }

  .ql-container {
    font-family: 'Montserrat', sans-serif;
  }

  .custom-file-label {
    display: flex;
    align-items: center;
  }

  &__description-ql-editor {
    p {
      margin: 0;
    }

    .ql-size-large {
      font-size: 1.5em;
    }

    pre {
      padding: 1rem !important;
      border-radius: 0.6rem;
    }
  }

  &-field {
    height: 32px;
  }

  .xmx-datepicker {
    width: 110px;

    .xmx-input:disabled,
    .xmx-input.disabled {
      background-color: unset;
      cursor: default;
    }

    .xmx-input {
      color: #0095e8;
      font-weight: 500;
      border: 0;
      box-shadow: none;
      padding: 0.438rem 0.6rem !important;
    }

    .xmx-input::placeholder {
      color: #c3c0d0;
      font-weight: 400;
    }

    .xmx-icon-calendar {
      display: none;
    }
  }

  .xmx-time {
    width: 80px;
    flex: unset;
    background-color: transparent;
    color: #0095e8;
    padding: 0.5rem;
    height: unset;
    line-height: 1;
    border: 0;
    font-weight: 500;

    &:focus {
      box-shadow: unset;
      background: #f5f8fa !important;
    }
  }
}

.block-updated-task {
  position: fixed;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  transform: translateY(50px);

  span {
    flex-grow: 1;
    text-align: left;
  }

  &.show {
    transform: translateY(-50px);
  }
}

//html .footer-fixed .content.app-content {
//  padding-bottom: 2.85rem !important;
//}

@media screen and (max-width: 992px) {
  .task {
    flex-direction: column;
    height: unset;

    .task-left-side {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    .task-right-side {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
</style>
