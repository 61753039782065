<template>
  <div class="task-timer tw-mt-4">
    <template v-if="showTimer">
      <!-- vertical timer -->
      <div class="mt-50 border-gray p-1 rounded-lg w-100">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <b-button
              :class="{run: running}"
              class="btn-icon btn-play"
              @click="() => (!running ? start(timeBegan === 0 || timeBegan === null) : stop())"
            >
              <font-ai
                v-if="!running"
                :icon="'fa-solid fa-play'"
                style="width: 20px; height: 20px; transform: translateX(1px)"
              />
              <font-ai v-else :icon="'fa-solid fa-pause'" style="width: 20px; height: 20px" />
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon ml-75" @click="reset">
              <font-ai :icon="'fa-solid fa-rotate-left'" style="width: 15px; height: 15px" />
            </b-button>
          </div>
          <div class="flex-grow-1 ml-1 text-center">
            <!--                        <p class="mb-0 font-small-2 w-100">{{ $t("Progress") }}</p>-->
            <!--                        <b-progress :max="100">-->
            <!--                            <b-progress-bar-->
            <!--                                variant="primary"-->
            <!--                                :value="valuesProgress[0]"-->
            <!--                                :animated="running"-->
            <!--                            >-->
            <!--                                &lt;!&ndash;                                <strong>{{ timesProgress[0] }}</strong>&ndash;&gt;-->
            <!--                            </b-progress-bar>-->
            <!--                            <b-progress-bar-->
            <!--                                variant="warning"-->
            <!--                                :value="valuesProgress[1]"-->
            <!--                                :animated="running"-->
            <!--                            >-->
            <!--                                &lt;!&ndash;                                <strong>{{ timesProgress[1] }}</strong>&ndash;&gt;-->
            <!--                            </b-progress-bar>-->
            <!--                        </b-progress>-->
          </div>
          <p class="text-timer ml-1 mb-0 h-100 tw-text-sky-500">
            {{ currentElapsedTime }}
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-1">
          <template v-for="(min, index) in minutesForCorrectTimer">
            <b-link
              v-if="min.sign !== '+-'"
              :key="index"
              :class="min.sign === '-' ? 'mr-50' : 'ml-50'"
              class="font-small-3"
              @click="min.sign === '-' ? timeReduction(min.minutes) : timeIncrease(min.minutes)"
              >{{ `${min.sign}${min.minutes}` }}
            </b-link>
            <b-form-group v-else :key="index" class="m-0">
              <b-input-group>
                <b-input-group-prepend class="cursor-pointer" is-text @click="timeReduction(1)">
                  <b-link class="d-flex align-items-center">
                    <feather-icon :icon="'MinusIcon'" class="font-medium-1" />
                  </b-link>
                </b-input-group-prepend>
                <b-input-group-append class="cursor-pointer" is-text @click="timeIncrease(1)">
                  <b-link class="d-flex align-items-center">
                    <feather-icon :icon="'PlusIcon'" class="font-medium-1" />
                  </b-link>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment/moment'
import Ripple from 'vue-ripple-directive'
import {BButton, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BLink} from 'bootstrap-vue'
import TasksService from '@/services/tasks.service'
import TimerService from '@/services/timer.service'
import {eventBus} from '@/main'

export default {
  components: {
    BButton,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  props: {
    showTimer: {
      type: Boolean,
      default: false,
    },
    estimatedTime: {
      type: Number,
      default: 0,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      minutesForCorrectTimer: [
        {
          sign: '-',
          minutes: 60,
        },
        {
          sign: '-',
          minutes: 30,
        },
        {
          sign: '-',
          minutes: 15,
        },
        {
          sign: '+-',
          minutes: 0,
        },
        {
          sign: '+',
          minutes: 15,
        },
        {
          sign: '+',
          minutes: 30,
        },
        {
          sign: '+',
          minutes: 60,
        },
      ],
      hours: Array.from({length: 24}).map((_, i) => i),
      valuesProgress: [0, 0],
      timesProgress: [0, 0],
      running: false,
      currentElapsedTime: '00:00:00',
      timeBegan: null,
      started: null,
      trackingTime: 0,
    }
  },
  mounted() {
    eventBus.$on('load-timer', () => {
      this.updateTimes(true)
      this.getTimerData()
    })
    eventBus.$on('update-timer', () => {
      // this.reset()
      clearInterval(this.started)
      this.started = null
      this.getTimerData()
    })
    this.getTimerData()
  },
  methods: {
    getTimerData() {
      TimerService.getTimer(this.$route.params.id).then(r => {
        let started_seconds = 0
        this.running = r.data.started
        this.timeBegan = r.data.start

        if (r.data.start !== null && r.data.start !== 0) {
          started_seconds = Number(moment().utc().valueOf().toString().slice(0, -3)) - r.data.start
        }

        this.trackingTime = (r.data['elapsed'] + started_seconds) * 1000

        this.$emit('update-show-timer', r.data.started || r.data['elapsed'] > 0)

        this.updateElapsedTime()

        clearInterval(this.started)
        if (r.data.started) {
          this.start(r.data.start === null || r.data.start === 0)
        }
      })
    },
    updateElapsedTime() {
      this.currentElapsedTime = moment('00:00:00', 'HH:mm:ss').add(this.trackingTime, 'milliseconds').format('HH:mm:ss')
      this.updateTimes()
    },
    updateTimes(first = false) {
      const time = moment(this.currentElapsedTime, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds'),
        seconds = moment(this.estimatedTime, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')

      if (first) {
        time > seconds ? (this.valuesProgress[0] = 100) : (this.valuesProgress[0] = (time / seconds) * 100)
        if (time > seconds) this.valuesProgress[1] = ((time - seconds) / seconds) * 100
      }

      if (time > seconds) {
        this.valuesProgress[0] = 100
        this.valuesProgress[1] = ((time - seconds) / seconds) * 100
      } else {
        this.valuesProgress[0] = (time / seconds) * 100
        this.valuesProgress[1] = 0
      }

      const timerValue = Math.ceil(time / 60) - this.$store.state.task.timeSpent
      this.$emit('update-time', timerValue)
    },
    timeReduction(minutes) {
      this.currentElapsedTime = moment(this.currentElapsedTime, 'HH:mm:ss')
        .subtract(minutes, 'minutes')
        .format('HH:mm:ss')
      this.trackingTime -= minutes * 60 * 1000
      this.updateTimes()
    },
    timeIncrease(minutes) {
      this.currentElapsedTime = moment(this.currentElapsedTime, 'HH:mm:ss').add(minutes, 'minutes').format('HH:mm:ss')
      this.trackingTime += minutes * 60 * 1000
      this.updateTimes()
    },
    start(request = false) {
      this.started = setInterval(this.clockRunning, 100)
      this.running = true

      if (request) {
        TimerService.startTimer(this.$route.params.id, {
          start: Number(moment().utc().valueOf().toString().slice(0, -3)),
        })
      }
    },
    stop() {
      this.running = false
      clearInterval(this.started)

      TimerService.stopTimer(this.$route.params.id, {
        pause: Number(moment().utc().valueOf().toString().slice(0, -3)),
      })
    },
    reset(update_task = false) {
      if (update_task) {
        TasksService.updateTask(this.$route.params.id, {
          timer_start: 0,
          timer_paused: 0,
          timer_started: false,
        })
      }
      this.running = false
      clearInterval(this.started)
      this.trackingTime = 0
      this.timeBegan = null
      this.updateElapsedTime()
    },
    clockRunning() {
      this.trackingTime += 100

      this.updateElapsedTime()
    },
  },
  beforeDestroy() {
    clearInterval(this.started)
  },
}
</script>

<style lang="scss">
.tippy-tooltip.light-theme {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.45rem;
  border: 1px solid #dadada;

  .tippy-backdrop {
    background-color: #fff;
  }

  .input-group-append {
    > div {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

$namespace: 'xmxx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #6f6c7c;
$primary-color: #0095e8;

@import '~vue2-datepicker/scss/index.scss';

.xmxx-datepicker {
  width: unset;

  .xmxx-input-wrapper {
    .xmxx-input {
      width: 125px;
      height: 22px;
      padding: 0;
      font-size: 26px;
      line-height: inherit;
      background-color: #fff;
      border: 0;
      border-radius: unset;
      box-shadow: unset;
      font-family: 'Roboto Mono', monospace;
      color: #2787ce;
      cursor: pointer;

      &:hover {
        color: #549fd9;
      }
    }

    .xmxx-icon-calendar {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.task-timer {
  position: relative;
}

.update-timer {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  color: #0095e8;
  outline: unset;

  &:hover {
    transform: scale(1.1) rotate(360deg);
  }
}

.btn-icon {
  border: 4px solid #e7ebf3 !important;
  color: #0095e8 !important;
  background-color: unset !important;
  border-radius: 50% !important;
  padding: 10px !important;
  cursor: pointer;
  transition: border 0.3s, box-shadow 0.3s ease;

  &:hover {
    border: 4px solid #0095e8 !important;
  }

  &.btn-play {
    width: 50px;
    height: 50px;
    padding: 10px 7.8px 10px 10px !important;
  }

  &.btn-play.run {
    animation: glowing 2s infinite;
    padding: 10px !important;
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #e7ebf3;
    border: 4px solid #e7ebf3 !important;
  }
  50% {
    box-shadow: 0 0 15px #0095e8;
    border: 4px solid #0095e8 !important;
  }
  100% {
    box-shadow: 0 0 5px #e7ebf3;
    border: 4px solid #e7ebf3 !important;
  }
}

.btn-icon-2 {
  border: 4px solid #e7ebf3 !important;
  color: #0095e8 !important;
  background-color: unset !important;
  border-radius: 50% !important;
  padding: 5px !important;

  p {
    margin: 0;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chart-timer {
  position: absolute;
  top: 99px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.btn-play-stop {
  width: 22px;
  height: 22px;
  position: relative;

  span {
    width: 6px;
    background: #2479ba;
    height: 22px;
    position: absolute;
    border-radius: 2px;
    transition: all 0.5s ease;

    &:nth-child(1) {
      transform: rotate(0deg) translate(3px);
      opacity: 0;
    }

    &:nth-child(2) {
      transform: translate(-9px);
    }

    &:nth-child(3) {
      transform: rotate(0deg) translate(3px);
    }
  }

  &.playing {
    span:nth-child(1) {
      border-radius: 3px;
      height: 22px;
      transform: rotate(120.34deg) translate(-2.9px, 2.9px);
      opacity: 1;
      z-index: 1;
    }

    span:nth-child(2) {
      border-radius: 3px;
      height: 22px;
      transform: rotate(60.28deg) translate(3px, 3px);
      z-index: 2;
    }

    span:nth-child(3) {
      border-radius: 3px;
      height: 22px;
      transform: rotate(180deg) translate(8px);
    }
  }
}
</style>
