<template>
  <section class="tw-px-6 tw-py-4 tw-border tw-rounded-xl">
    <b-skeleton animation="fade" height="20px" width="85%"></b-skeleton>
    <b-skeleton animation="fade" width="20%"></b-skeleton>
    <b-skeleton animation="fade" width="28%"></b-skeleton>
    <b-skeleton animation="fade" width="23%"></b-skeleton>
    <b-skeleton animation="fade" width="0%"></b-skeleton>

    <b-row>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="63%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="52%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="71%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="31%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="65%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="42%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="37%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="52%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="71%"></b-skeleton>
      </b-col>
      <b-col md="4">
        <b-skeleton animation="fade" height="18px" width="31%"></b-skeleton>
      </b-col>
    </b-row>
    <b-skeleton animation="fade" width="0%"></b-skeleton>
    <b-skeleton animation="fade" width="0%"></b-skeleton>
    <b-skeleton animation="fade" width="80%"></b-skeleton>
    <b-skeleton animation="fade" width="45%"></b-skeleton>
    <b-skeleton animation="fade" width="60%"></b-skeleton>
    <b-skeleton animation="fade" width="25%"></b-skeleton>
  </section>
</template>

<script>
import {BCol, BRow, BSkeleton} from 'bootstrap-vue'

export default {
  components: {BCol, BSkeleton, BRow},
}
</script>

<style lang="scss" scoped></style>