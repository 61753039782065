<template>
  <div v-if="imageData !== null" class="image-viewer" @click="$store.commit('task/CHANGE_IMAGE_DATA', null)">
    <div class="image-viewer__image">
      <img
        :src="imageData.url"
        :style="{'max-width': `${imageData.maxWidth}px`, 'max-height': `${imageData.maxHeight}px`}"
        alt=""
      />
    </div>
    <!--    <font-ai-->
    <!--      :icon="['far', 'circle-xmark']"-->
    <!--      class="align-middle icon-color text-white-hover cursor-pointer font-large-1 ml-1"-->
    <!--      @click="$store.commit('task/CHANGE_IMAGE_URL', '')"-->
    <!--    />-->
  </div>
</template>

<script>
export default {
  computed: {
    imageData() {
      return this.$store.state.task.imageData
    },
  },
}
</script>

<style lang="scss" scoped>
.image-viewer {
  position: fixed;
  z-index: 100000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px 80px;
  display: flex;
  justify-content: center;
  cursor: zoom-out;

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
