<template>
  <div>
    <div v-for="(item, idx) in descList" :key="idx" :class="{'desc-text': item.type === 'text'}" class="desc-block">
      <div v-if="item.type === 'text'" class="d-flex rounded-nrm">
        <TaskTipTapEditor
          ref="editor"
          v-model="item.string"
          :readonly="readOnly"
          @update="onEditorChange($event, item, idx)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor'

export default {
  components: {
    TaskTipTapEditor,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Array, String],
    },
    taskData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      modalShow: false,
      savingArray: [],
      descList: [],
      blocks: [
        // {
        //   title: 'Text',
        //   icon: 'fa-align-left',
        // },
        {
          title: 'Code',
          icon: 'fa-code',
        },
        {
          title: 'ToDo',
          icon: 'fa-list-check',
        },
      ],
      timer: null,
    }
  },
  watch: {
    descList() {
      this.$emit('input', this.descList)
    },
    value() {
      this.loadData()
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.savingArray = []
      this.descList = this.value
      this.value.forEach(() => {
        this.savingArray.push({
          loading: false,
          saving: false,
        })
      })
    },
    // deleteBlock(index, el) {
    //   this.$emit('change-reader')
    //   this.descList.splice(index, 1)
    //   TasksService.deleteDescription(el.id).then(() => {
    //     TasksService.getTask(this.taskData.id)
    //   })
    // },
    // urlFile(event, i) {
    //   this.descList[i].img.url = URL.createObjectURL(event.target.files[0])
    //   this.descList[i].img.description = event.target.files[0].name
    // },
    // choiceBlock(val) {
    //   const type = val.toLowerCase()
    //   TasksService.createDescription(this.taskData.id, {
    //     type: type,
    //     string: '',
    //     ordering: this.descList.length,
    //     img: {
    //       file: null,
    //       url: null,
    //       description: '',
    //     },
    //     todoList: [],
    //     code: {
    //       lang: type === 'code' ? 'js' : '',
    //       code: '',
    //     },
    //   }).then(() => {
    //     TasksService.getTask(this.taskData.id)
    //   })
    // },
    onEditorChange() {
      this.$emit('change-reader')
    },
    // updateCode(e, el, index) {
    //   this.$emit('change-reader')
    //   this.descList[index].code.code = e
    // },
    // updateTodo(e, el, index) {
    //   this.$emit('change-reader')
    //   this.descList[index].todoList = e
    // },
    // onEnd() {
    //   this.descList.forEach((el, index) => {
    //     el.ordering = index
    //     TasksService.updateDescription(el.id, {
    //       ...el,
    //       ordering: index,
    //     })
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
.desc-image {
  max-height: 500px;
  margin: 0 auto;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    cursor: default;
    transition: background-color 0.3s, padding-left 0.3s, padding-right 0.3s ease;

    img {
      max-height: 500px;
    }
  }

  &__container:hover {
    background-color: rgba(182, 182, 182, 0.07);
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__description {
    border: 0;
  }
}

.dark-layout .desc-image__container {
  img {
    border: 1px solid #404656;
    margin: 0 auto;
  }
}

.desc-blocks-container {
  cursor: text;

  > div:not(:first-child) {
    margin-top: 1rem;
  }

  .desc-block {
    position: relative;

    &.desc-text {
    }

    &__title {
      position: absolute;
      top: -15px;
      left: 15px;
      background: #fbfbfb;
      padding: 0 5px;
      border: 1px solid #ebe9f1;
      border-radius: 7px;
      text-transform: uppercase;
      font-size: 12px;
    }

    .quill-editor .ql-container {
      border-radius: 0.65rem !important;
    }

    > div:hover {
      .desc-block__menu {
        border-radius: 0.65rem;
        position: absolute;
        top: -1.6rem;
        opacity: 1;
      }
    }
  }
}

.add-editor-block {
  padding: 5px;
  color: #b7b5be;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e8e8e8;
  cursor: pointer;

  > svg {
    transition: color 0.3s ease;
  }

  > svg:not(:last-child) {
    margin-right: 1rem;
  }

  > svg:hover {
    color: #0095e8ff;
  }
}
</style>