import axios from 'axios'

const axiosGPT = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: 'https://api.openai.com/v1/',
  timeout: 0,
  headers: {
    Accept: 'application/json',
    // eslint-disable-next-line no-undef
    Authorization: `Bearer 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`,
  },
})

class GPTService {
  correctTitle(data) {
    return axiosGPT.post('chat/completions', {
      model: 'gpt-3.5-turbo', // Модель ChatGPT
      messages: [
        {
          role: 'system',
          content:
            'Вам будет предоставлен текст, и ваша задача - исправить орфографические и пунктуационные ошибки на русском языке.',
        },
        {
          role: 'user',
          content: data,
        },
      ],
    })
  }

  getTitleForTask(data) {
    return axiosGPT.post('chat/completions', {
      model: 'gpt-3.5-turbo', // Модель ChatGPT
      messages: [
        {
          role: 'system',
          content:
            'Вам будет предоставлен текст, и ваша задача - составить заголовок для задачи из этого текста, длина не более 10 слов, заголовок не должен быть в кавычках.',
        },
        {
          role: 'user',
          content: data,
        },
      ],
    })
  }
}

export default new GPTService()
