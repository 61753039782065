<template>
  <div class="task-notes">
    <b-tabs class="mb-0" nav-wrapper-class="" @input="getHistory">
      <b-tab active>
        <template #title>
          <feather-icon icon="MessageSquareIcon" />
          <span>{{ $t('Comments') }}</span>
        </template>
        <div>
          <TaskTipTapEditor ref="reader" v-model="newMessage" @create-message-task="createMessageTask" />

          <TaskTimer
            ref="timer"
            :estimated-time="estimatedTime"
            :show-timer="showElapsedTime"
            @update-time="currentElapsedTimeInMinutes = $event"
            @update-show-timer="showElapsedTime = $event"
          />

          <div class="tw-flex tw-items-center mt-1 tw-mb-2">
            <b-overlay
              :show="onLoadingCreatedNote"
              class="d-inline-block"
              opacity="0.6"
              rounded
              spinner-small
              spinner-variant="primary"
            >
              <b-button :disabled="onLoadingCreatedNote" variant="outline-primary" @click="createMessageTask">
                {{ $t('Create comment') }}
              </b-button>
            </b-overlay>

            <div class="tw-flex tw-flex-col tw-items-start tw-mb-0 tw-ml-2">
              <b-link
                v-if="!showElapsedTime"
                class="font-small-3 d-flex align-items-center"
                @click="showElapsedTime = true"
              >
                <feather-icon :icon="'PlusIcon'" class="mr-25" />
                <span>{{ $t('Add spend time') }}</span>
              </b-link>
            </div>
          </div>
        </div>
        <div v-if="$store.state.task.notes.length > 0" class="tw-flex tw-justify-end tw-items-center mt-1 mb-50">
          <p class="m-0 cursor-pointer" @click="notesSort">{{ $t('Sorting') }}</p>
          <feather-icon :icon="sortingNotes ? 'ArrowUpIcon' : 'ArrowDownIcon'" class="ml-50" />
        </div>
        <div class="media-list">
          <template v-if="!$store.state.osBoard.loading">
            <b-media
              v-for="(note, index) in $store.state.task.notes"
              :key="index"
              :class="{'bg-user-primary': note.author.id === $store.state.osBoard.user.id}"
              class="px-0 pt-75 pb-25 rounded-nrm"
              vertical-align="top"
            >
              <template #aside>
                <b-avatar :src="note.author.avatar" size="35" variant="light-primary" />
              </template>
              <div class="tw-flex tw-relative">
                <div>
                  <h6 class="media-heading mb-0">{{ note.author.title }}</h6>
                  <span class="text-muted font-small-2">{{ dateTimeFormat(note.created_at) }}</span>
                </div>
                <b-dropdown
                  class="chart-dropdown position-absolute zindex-4 tw-right-0"
                  no-caret
                  toggle-class="p-0"
                  variant="link"
                >
                  <template #button-content>
                    <feather-icon class="text-body cursor-pointer" icon="MoreVerticalIcon" size="16" />
                  </template>
                  <b-dropdown-item @click="replyTask(note)">
                    {{ $t('Reply') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="note.author.id === $store.state.osBoard.user.id"
                    variant="light-danger"
                    @click="removeTask(note.id)"
                  >
                    {{ $t('Remove') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <b-card-text
                v-if="note.comment"
                class="mt-50 font-small-3 p-0 tw-break-words"
                v-html="note.comment"
              ></b-card-text>
              <b-card-text
                v-if="note.time_elapsed !== 0"
                class="d-flex align-items-center mt-50 font-small-3 p-0 text-primary"
              >
                <feather-icon :icon="'ClockIcon'" class="font-medium-1 mr-25" />
                {{ $t('Timestamp') }}:
                <span class="ml-25 font-weight-bold text-primary">{{ elapsedTimeForNote(note.time_elapsed) }}</span>
              </b-card-text>
              <!--              <p class="btn-link font-small-3 mb-0 mt-75 cursor-pointer">Reply</p>-->
            </b-media>
          </template>
          <template v-else>
            <b-media class="pb-0 mt-3 py-75" vertical-align="top">
              <template #aside>
                <b-skeleton animation="fade" height="40px" type="avatar" width="40px"></b-skeleton>
              </template>
              <h6 class="media-heading mb-0">
                <b-skeleton animation="fade" width="68%"></b-skeleton>
              </h6>
              <span class="font-small-2">
                <b-skeleton animation="fade" width="31%"></b-skeleton>
              </span>
              <b-card-text class="mt-2 font-small-3 p-0">
                <b-skeleton animation="fade" class="mb-0" width="38%"></b-skeleton>
              </b-card-text>
            </b-media>
            <b-media class="px-0 py-75 mt-2" vertical-align="top">
              <template #aside>
                <b-skeleton animation="fade" height="40px" type="avatar" width="40px"></b-skeleton>
              </template>
              <h6 class="media-heading mb-0">
                <b-skeleton animation="fade" width="68%"></b-skeleton>
              </h6>
              <span class="font-small-2">
                <b-skeleton animation="fade" width="31%"></b-skeleton>
              </span>
              <b-card-text class="mt-2 font-small-3 p-0">
                <b-skeleton animation="fade" class="mb-0" width="38%"></b-skeleton>
              </b-card-text>
            </b-media>
          </template>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span>History</span>
        </template>
        <div v-if="isLoadingHistory" class="pl-25">
          <os-loader />
        </div>
        <app-timeline class="mt-2 tw-mb-2">
          <app-timeline-item v-for="(historyItem, index) in historyHandler" :key="index" class="pl-2">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <b-media class="align-items-center">
                <template #aside>
                  <b-avatar :src="''" size="32" variant="light-primary" />
                </template>
                <p class="mb-0 font-small-3 font-weight-bold tw-leading-4 tw-text-neutral-600">
                  {{ historyItem.user ? historyItem.user.title : 'John Doe' }}
                </p>
                <p class="font-small-2 tw-leading-4 tw-text-neutral-400">
                  {{ historyItem.user ? historyItem.user.role_name : 'role' }}
                </p>
              </b-media>
              <small class="text-muted">{{ dateTimeFormat(historyItem.createdAt) }}</small>
            </div>
            <div>
              <b-card
                v-for="(historyEl, historyElIndex) in historyItem.fields"
                :key="historyElIndex"
                class="mt-1 border mb-0 px-1 py-75"
                no-body
              >
                <div class="d-flex align-items-center mb-50 font-small-3">
                  {{ historyFieldNameHandler(historyEl.fieldName) }}
                </div>
                <div>
                  <div class="tw-grid tw-grid-cols-[auto_auto_auto_1fr] align-items-center tw-w-full">
                    <template v-if="isUser(historyEl.fieldName)">
                      <b-media class="align-items-center">
                        <template #aside>
                          <b-avatar :src="''" size="31" variant="light-primary" />
                        </template>
                        <p class="mb-0 font-small-3 font-weight-bold tw-leading-4 tw-text-neutral-600">
                          {{ historyEl.newValue.first_name }} {{ historyEl.newValue.last_name }}
                        </p>
                        <p class="font-small-2 tw-leading-4 tw-text-neutral-400">
                          {{ historyEl.newValue['worker_type'] }}
                        </p>
                      </b-media>
                      <feather-icon class="mx-75" icon="ArrowRightIcon" size="16" />
                      <b-media class="align-items-center">
                        <template #aside>
                          <b-avatar :src="''" size="31" variant="light-primary" />
                        </template>
                        <p class="mb-0 font-small-3 font-weight-bold tw-leading-4 tw-text-neutral-600">
                          {{ historyEl.oldValue.first_name }} {{ historyEl.oldValue.last_name }}
                        </p>
                        <p class="font-small-2 tw-leading-4 tw-text-neutral-400">
                          {{ historyEl.oldValue['worker_type'] }}
                        </p>
                      </b-media>
                    </template>
                    <template v-else>
                      <div
                        v-tippy="{content: historyEl.newValue, delay: [300, 0]}"
                        class="tw-flex tw-items-center tw-text-xs tw-font-semibold tw-w-full tw-overflow-hidden tw-bg-neutral-100 tw-text-neutral-500 tw-rounded-md tw-py-1 tw-px-2"
                      >
                        <span class="tw-truncate">{{ historyEl.newValue }}</span>
                      </div>
                      <feather-icon class="mx-50" icon="ArrowRightIcon" size="16" />
                      <div
                        v-tippy="{content: historyEl.oldValue, delay: [300, 0]}"
                        class="tw-flex tw-items-center tw-text-xs tw-font-semibold tw-w-full tw-overflow-hidden tw-bg-sky-100 tw-text-sky-500 tw-rounded-md tw-py-1 tw-px-2"
                      >
                        <span class="tw-truncate">{{ historyEl.oldValue }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </b-card>
            </div>
          </app-timeline-item>
        </app-timeline>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
  BOverlay,
  BSkeleton,
  BTab,
  BTabs,
} from 'bootstrap-vue';
import moment from 'moment';
import TasksService from '@/services/tasks.service';
import TaskTimer from '@/components/Task/TaskTimer';
import store from '@/store';
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BCardText,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BSkeleton,
    BOverlay,
    BCard,
    TaskTimer,
    TaskTipTapEditor,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    estimatedTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      notes: [],
      history: [],
      sortingNotes: true,
      newMessage: '',
      bar: {variant: 'primary', value: 75},
      currentElapsedTimeInMinutes: 0,
      showElapsedTime: false,
      onLoadingCreatedNote: false,
      isLoadingHistory: true,
    };
  },
  mounted() {
    store.commit('osBoard/LOADING', true);
    this.isLoadingHistory = true;
    this.getNotes();
  },
  computed: {
    historyHandler() {
      if (this.history.length === 0) return [];
      return this.history.map(el => ({
        ...el,
        createdAt: el.created_at,
        fields: Object.keys(el['new_values']).map(historyEl => ({
          fieldName: historyEl,
          oldValue: this.historyValueHandler(historyEl, el['old_values'][historyEl]),
          newValue: this.historyValueHandler(historyEl, el['new_values'][historyEl]),
        })),
      }));
    },
  },
  methods: {
    isUser(field) {
      return ['assigned', 'performer'].includes(field);
    },
    historyValueHandler(name, value) {
      if (name === 'status') return value.status ? value.status.replaceAll('_', ' ').toUpperCase() : '';
      if (name === 'due_date' || name === 'start_date') return value === 'None' ? 'None' : this.dateTimeFormat(value);
      if (name === 'assigned' || name === 'performer' || name === 'created_by' || name === 'subject') return value;
      return value.toUpperCase();
    },
    historyFieldNameHandler(name) {
      if (name === 'status') return this.$t('Status');
      if (name === 'assigned') return this.$t('Assigned');
      if (name === 'priority') return this.$t('Priority');
      if (name === 'performer') return this.$t('Performer');
      if (name === 'worker_type') return this.$t('Worker type');
      if (name === 'is_completed') return this.$t('Is completed');
      if (name === 'subject') return this.$t('Subject');
      if (name === 'estimate') return this.$t('Estimate');
      if (name === 'created_by') return this.$t('Created by');
      if (name === 'sprint') return this.$t('Sprint');
      if (name === 'start_date') return this.$t('Start date');
      if (name === 'due_date') return this.$t('Due date');
      return name;
    },
    getHistory(e) {
      if (e === 1) {
        TasksService.getHistoryTask(this.$route.params.id).then(r => {
          this.isLoadingHistory = false;
          this.history = r.data;
        });
      }
    },
    removeTask(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Yes',
        })
        .then(value => {
          if (value) TasksService.removeNote(id).then(() => this.getNotes());
        });
    },
    async replyTask(note) {
      await this.$refs.reader.editor.chain().focus().clearContent().run();
      await this.$refs.reader.editor
        .chain()
        .focus()
        .insertContent(
          `<blockquote class="reply-blockquote">${note.comment}</blockquote><p data-placeholder="Write something …" class="is-empty"><span data-type="mention" class="mention" data-id="${note.author.username}" contenteditable="false">@${note.author.username}</span> </p>`
        )
        .run();
      this.$refs.reader.editor.commands.focus('end');
    },
    dateTimeFormat(data) {
      return moment(data, 'YYYY-MM-DD HH:mm:ss').format(
        this.$i18n.locale === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD.MM.YYYY HH:mm'
      );
    },
    notesSort() {
      const arr = this.$store.state.task.notes;
      this.sortingNotes = !this.sortingNotes;
      if (!this.sortingNotes) {
        this.$store.commit(
          'task/GET_NOTES',
          arr.sort((a, b) => a.id - b.id)
        );
      } else {
        this.$store.commit(
          'task/GET_NOTES',
          arr.sort((a, b) => b.id - a.id)
        );
      }
    },
    elapsedTimeForNote(minutes) {
      return moment('00:00', 'HH:mm')
        .add(minutes, 'minutes')
        .format(this.$i18n.locale === 'en' ? 'HH[h] mm[m]' : 'HH[ч] mm[м]');
    },
    getNotes() {
      const {id: paramsId} = this.$route.params;
      let id = paramsId ? paramsId : this.$store.state.task.task.id;

      TasksService.getNotes(id).then(r => {
        store.commit('task/INCREASE_TIME_SPENT', 0);
        this.$store.commit(
          'task/GET_NOTES',
          r.data.sort((a, b) => b.id - a.id)
        );
        r.data.forEach(el => {
          store.commit('task/INCREASE_TIME_SPENT', store.state.task.timeSpent + el.time_elapsed);
        });

        store.commit('osBoard/LOADING', false);
        this.onLoadingCreatedNote = false;
        this.clearData();
        setTimeout(() => {
          const images = document.getElementsByClassName('reader-img');
          for (let i = 0; i < images.length; i++) {
            images[i].classList.add('cursor-zoom-in');
            images[i].addEventListener('click', () => {
              store.commit('task/CHANGE_IMAGE_DATA', {
                url: images[i].src,
                maxWidth: images[i].naturalWidth,
                maxHeight: images[i].naturalHeight,
              });
            });
          }
          const images2 = document.getElementsByClassName('viewer-image');
          for (let i = 0; i < images2.length; i++) {
            images2[i].classList.add('cursor-zoom-in');
            images2[i].addEventListener('click', () => {
              store.commit('task/CHANGE_IMAGE_DATA', {
                url: images2[i].src,
                maxWidth: images2[i].naturalWidth,
                maxHeight: images2[i].naturalHeight,
              });
            });
          }
        }, 500);
      });
    },
    createMessageTask() {
      if (this.$refs.reader.count > 0) {
        this.onLoadingCreatedNote = true;
        const {id: paramsId} = this.$route.params;
        let id = paramsId ? paramsId : this.$store.state.task.task.id;

        const hours_minutes = this.$refs.timer.currentElapsedTime.split(':');
        const hours = Number(hours_minutes[0]);
        const minutes = Number(hours_minutes[1]);

        TasksService.createNote(id, {
          comment: this.newMessage,
          time_elapsed: hours * 60 + minutes,
        }).then(() => {
          this.$refs.timer.trackingTime = 0;
          this.$refs.timer.currentElapsedTime = '00:00';
          this.getNotes();
        });
        this.addElapsedTime();
      }
    },
    addElapsedTime() {
      let sum = 0;
      this.notes.forEach(el => {
        const time = moment(el.elapsedTime, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
        if (time !== 0) sum += time;
      });
      this.$store.commit(
        'task/INCREASE_TIME_SPENT',
        sum === 0 ? '00:00:00' : moment('00:00:00', 'HH:mm:ss').add(sum, 'seconds').format('HH:mm:ss')
      );
    },
    clearData() {
      this.newMessage = '';
      this.showElapsedTime = false;
    },
  },
};
</script>

<style lang="scss">
.task-notes {
  .media-body {
    width: calc(100% - 35px - 16px);

    .card-text {
      img {
        max-width: 200px;
        max-height: 200px;
        object-fit: contain;
      }
    }
  }

  .media-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0;

    > .media {
      padding: 0.75rem 0.75rem !important;
      margin: 0 -0.75rem;
      width: calc(100% + 1.5rem);

      &.bg-user-primary {
        background: #f5f8fa !important;
      }

      code:not([class]) {
        color: #e83e8c;
        background-color: #dce4ed;
        font-weight: 500;
      }

      blockquote {
        padding-left: 0.7rem;
        border-left: 2px solid rgba(#0d0d0d, 0.1);
        font-size: 12px;
        color: #b9b9c3;

        blockquote {
          margin-bottom: 0.6rem;
        }

        .mention {
          color: #b9b9c3;
          border-color: #b9b9c3;
        }

        > p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      table {
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -khtml-border-radius: 5px;
        border: 1px solid #ced4da;

        tbody {
          tr:not(:last-child) {
            th,
            td {
              border-bottom: 1px solid #ced4da;
            }
          }

          tr {
            th:not(:last-child),
            td:not(:last-child) {
              border-right: 1px solid #ced4da;
            }
          }
        }

        td,
        th {
          min-width: 1em;
          //border: 1px solid #ced4da;
          padding: 3px 5px;
          vertical-align: top;
          box-sizing: border-box;
          position: relative;

          > * {
            margin-bottom: 0;
          }
        }

        th {
          font-weight: bold;
          text-align: left;
          background-color: #f1f3f5;
        }

        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #2479b929;
          pointer-events: none;
        }

        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: -2px;
          width: 4px;
          background-color: #adf;
          pointer-events: none;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .history-table {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px 15px;
  }

  .timeline-item .timeline-item-point {
    top: 10px !important;
  }
}
</style>
